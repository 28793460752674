import React from "react";
import MarkdownContainer from "../misc/markdown-container";
import MarketoForm from "../forms/marketo-form";

const ResourceAsset = ({ asset }) => {
    return (
        <section className="st-section">
            <div className="st-content asset-pages">
                <h1 className="st-heading--1">{asset.pageHeading}</h1>
                {asset.image ? (
                    <>
                        {!asset.imagePosition ||
                            (asset.imagePosition[0] === "Above Content" && (
                                <>
                                    <img className={`product-img`} src={asset.image.file.url} alt={asset.image.title} />
                                    <MarkdownContainer content={asset.pageContent.pageContent} />
                                </>
                            ))}
                        {asset.imagePosition[0] === "Below Content" && (
                            <>
                                <MarkdownContainer content={asset.pageContent.pageContent} />
                                <img className={`product-img`} src={asset.image.file.url} alt={asset.image.title} />
                            </>
                        )}
                    </>
                ) : (
                    <MarkdownContainer content={asset.pageContent.pageContent} />
                )}
            </div>
            <div className={`st-form`}>
                <MarketoForm
                    formHeader={asset.marketoFormHeading}
                    formId={asset.marketoFormId}
                    formConversionType={asset.formConversionType}
                    formCallback={asset.formThankYouType}
                    formThankYouMessage={asset.marketoFormThankYouText?.marketoFormThankYouText ?? null}
                />
            </div>
        </section>
    );
};

export default ResourceAsset;
