import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";
import ResourceAsset from "../components/resources/resource-assets";

const AssetPage = ({ data, pageContext }) => {
    const page = data.contentfulAssetPages;
    const menus = pageContext.menus?.items;
    const bodyClasses = `${pageContext.type} resources_${page.slug} ps-with-nav`;

    return (
        <Layout menus={menus} headerType={`page`} navType="lp">
            <Seo
                type={"Page"}
                title={page.pageTitleMeta}
                description={page.pageDescriptionMeta}
                slug={`/resources/${page.slug}`}
                bodyClass={bodyClasses}
                robots={page.metaRobots}
            />
            <ResourceAsset asset={page} />
        </Layout>
    );
};

export default AssetPage;

export const query = graphql`
    query($slug: String!) {
        contentfulAssetPages(slug: { eq: $slug }) {
            contentful_id
            slug
            pageTitleMeta
            pageHeading
            marketoFormHeading
            marketoFormId
            pageDescriptionMeta
            pageContent {
                pageContent
            }
            formConversionType
            formThankYouType
            # marketoFormVideoUrl
            image {
                file {
                    url
                }
                title
            }
            imagePosition
            marketoFormThankYouText {
                marketoFormThankYouText
            }
            metaRobots
        }
    }
`;
